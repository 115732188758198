const prod = {
  host: 'https://us-central1-hailify-46a2d.cloudfunctions.net/api',
  storeUrl: 'https://play.google.com/store/apps/details?id=com.hailify.drive',
  stripeKey: 'sk_test_Krgn2vb4VazoadFxcmSNXtik',
  fbconfig: {
    apiKey: 'AIzaSyBITEyDlJ2_pEDEbGQqCyLGvNJpK8k8vK4',
    authDomain: 'hailify-46a2d.firebaseapp.com',
    databaseURL: 'https://hailify-46a2d.firebaseio.com',
    projectId: 'hailify-46a2d',
    storageBucket: 'hailify-46a2d.appspot.com',
    messagingSenderId: '913495397211',
  },
};

const develop = {
  host: 'https://us-central1-hailify-develop.cloudfunctions.net/api',
  storeUrl: 'https://play.google.com/store/apps/details?id=com.hailify.drive',
  stripeKey: 'sk_test_Krgn2vb4VazoadFxcmSNXtik',
  fbconfig: {
    apiKey: 'AIzaSyD90NRJ-kx_AaOGf7GjGosn5qOMz38uzgw',
    authDomain: 'hailify-develop.firebaseapp.com',
    databaseURL: 'https://hailify-develop.firebaseio.com',
    projectId: 'hailify-develop',
    storageBucket: 'hailify-develop.appspot.com',
    messagingSenderId: '938719777853',
  },
};


export default prod;
