import React, { Component } from 'react';
import { withRouter } from 'react-router';
import './Payment.scss';
import TextField from '@material-ui/core/TextField';
import {Button} from '@material-ui/core';
import config from '../config';
import {injectStripe, CardElement} from 'react-stripe-elements';
import Axios from 'axios';
import ErrorDialog from './ErrorDialog';
import  { Redirect } from 'react-router-dom';

class Payment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            first: '',
            last:'',
            phone: '',
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipcode: '',
            email: '',
            card: null,
            error: '',
            errors: false,
            errorOpen: false,
            selectedPlan: null,
            userId: null,
            user: null,
            plans: null,
            selected: 0,
            source: '',
            paymentSuccess:false,
            isLoading:false,
            price:'$9.99',
        }
    }

    async componentDidMount() {
        try {
            const userid = await this.checkTokenGetInfo();
            const user = await this.setUserId();
            const plans = await this.getPlans();
        } catch(e) {
            console.error("Problem", e)
        }
    }

    setLoading = (loading) => {
        this.setState({ isLoading: loading });
    }

    getUrlVars = (parameter) => {
      const reg = new RegExp(`[?&]${parameter}=([^&#]*)`, 'i');
      const string = reg.exec(window.location.href);
      return string ? string[1] : undefined;
    };

    handleChange = name => (event) => {
        this.setState({
          [name]: name === 'phone' ? event : event.target.value,
        });
    };

    handleCloseModal = () => {
        this.setState({
            errorOpen: false
        });
    }

    cardOnChange = (data) => {
        this.setState({ card: data });
    }

    cardOnReady = (element) => {
        this.cardElement = element;
    }

    errorHandler = (myError) => {
        if (myError) {
            this.setState({
              error: myError,
              errorOpen: true,
              errors: true,
            });
        } else {
            this.setState({
              error: '',
              errorOpen: false,
            });
        }
    }

    checkTokenGetInfo = () => {
        const idToken = this.getUrlVars('idToken') || '';
        this.setLoading(true);
        return Axios.get(`${config.host}/v1/user`, {
          headers: { Authorization: `Bearer ${idToken}` },
        }).then(result => {
          // should return userId
          console.log(result.data.user_id);
          this.setState({
            userId: result.data.user_id,
          });
          this.setLoading(false);
        }).catch(error => {
          console.log(error);
          this.errorHandler('Error Loading Profile');
          this.setLoading(false);
        });
    }

    setUserId = () => {
        const idToken = this.getUrlVars('idToken') || '';
        this.setLoading(true);
        return Axios.get(`${config.host}/v1/users/${this.state.userId}`, {
          headers: { Authorization: `Bearer ${idToken}` },
        }).then(result => {
          // should return user
          this.setState({
            user: result.data,
          });
          this.setLoading(false);
        }).catch(error => {
          this.errorHandler('Error Loading Profile');
          this.setLoading(false);
        });
    }

    getPlans = () => {
        const idToken = this.getUrlVars('idToken') || '';
        this.setLoading(true);
        return Axios.get(`${config.host}/v1/stripe/plans`, {
          headers: { Authorization: `Bearer ${idToken}` },
        }).then(result => {
          // should return plans
          console.log(result.data);
          this.setState({
            plans: result.data,
            selectedPlan: result.data[1],
          });
          this.setLoading(false);
        }).catch(error => {
          console.log(error);
          this.errorHandler('Error Loading Plans');
          this.setLoading(false);
        });
    }

    setPlan = async () => {
        const idToken = this.getUrlVars('idToken') || '';
        this.setLoading(true);
        return Axios.post(`${config.host}/v1/users/${this.state.userId}/plan`,
          { plan_id: this.state.selectedPlan.id },
          {
            headers: { Authorization: `Bearer ${idToken}` },
          }).then(result => {
            // should set user plan
            console.log(result.data);
            this.setState({
                paymentSuccess: true
            });
            this.setLoading(false);
          }).catch(error => {
            console.log(error);
            this.errorHandler('Error Setting Plan');
            this.setLoading(false);
          });
    }

    setCardSource = () => {
        console.log('setCardSource');
        const idToken = this.getUrlVars('idToken') || '';
        this.setLoading(true);
        return Axios.post(`${config.host}/v1/stripe/source`,
          { source_uid: this.state.source },
          {
            headers: { Authorization: `Bearer ${idToken}` },
          }).then(result => {
            // should return customer info
            console.log(result.data);
            this.setLoading(false);
          }).catch(error => {
            console.log(error);
            this.errorHandler('Error Setting Card Source');
            this.setLoading(false);
          });
    }

    setSource = async () => {

        const ownerInfo = {
          type: 'card',
          owner: {
            name: this.state.name,
            address: {
              line1: this.state.address1,
              line2: this.state.address2,
              city: this.state.city,
              postal_code: this.state.zipcode,
              country: 'US', // TODO!
            },
            email: this.state.user.email,
            phone: this.state.user.phone,
          },
        };

        this.setLoading(true);

        return this.props.stripe.createSource(ownerInfo)
            .then((result) => {
                this.setLoading(false);
                if (result.error) {
                    console.log(result.error);
                    this.errorHandler('Error: '+result.error.message);
                    return;
                } else {
                    this.setState({
                        source: result.source.id
                    });
                }
            }).catch(error => {
              console.log(error);
              this.errorHandler('Please check your information');
              this.setLoading(false);
            });
    }

    handlePlanClick = (plan) => {
        if (this.state.isLoading) {
            return;
        }
        this.setState({
            selectedPlan: plan,
        });
    }

    setSelectedPlan = (num) => {
        if (this.state.isLoading) {
            return;
        }
        this.setState({
            selected: num,
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({
          errors: false,
          paymentSuccess: false,
          errorOpen: false
        });

        const { card } = this.state;
        if (!card || !card.complete || card.empty || !this.cardElement) {
          this.errorHandler('Card Info is not complete');
          return;
        }

        //make stripe call
        try {
            const currentSource = await this.setSource();
            const cardSource = await this.setCardSource();
            const currentPlan = await this.setPlan();
        } catch (e) {
            console.log(e);
            this.setState({
              errors: true,
            });
            //this.errorHandler('Error setting plan');
        } finally {
            //this.props.history.push('/success');
        }

    }

    canSubmit = () => this.state.name
      && this.state.address1
      && this.state.city
      && this.state.state
      && this.state.zipcode
      && this.state.card
      && this.state.card.complete
      && !this.state.card.empty
      && this.cardElement
      && this.state.selectedPlan
      && !this.state.isLoading

    render() {
        const { plans, errorOpen, error } = this.state;

        if (this.state.paymentSuccess && !this.state.errors){
            window.location = "http://www.hailify.com/paymentdone"
            return <Redirect to='/success'/>
        }
        return (
            <div className="Payment">
                <div className="top-section">
                      <div className="main-text">
                          <h1>Choose a plan to continue using Hailify:</h1>
                      </div>
                      <div className="sub-text">
                      </div>
                </div>
                <div className="plan-buttons">
                    <div className={`plan-button ${(this.state.selected===0) ? 'selected' : ''}`} onClick={() => { this.handlePlanClick(plans[1]); this.setSelectedPlan(0);}}>
                        <h3>$9.99/month</h3>
                        <span>billed monthly</span>
                    </div>
                    <div className={`plan-button ${(this.state.selected===1) ? 'selected' : ''}`} onClick={() => { this.handlePlanClick(plans[2]); this.setSelectedPlan(1);}}>
                        <h3>$7.99/month</h3>
                        <span>billed yearly</span>
                    </div>
                </div>
                <div className="form-section">
                    <div className="single-row plan-title">
                        <h2>Payment details</h2>
                    </div>
                    <div className="single-row">
                        <TextField
                            style={{ width: '100%', marginRight: '16px' }}
                            id="standard-name"
                            label="Full Name"
                            value={this.state.name}
                            error={this.state.name === ''}
                            helperText={this.state.name ? '' : 'Required'}
                            onChange={this.handleChange('name')}
                            margin="normal" />
                        <TextField
                            style={{ width: '100%', marginRight: '16px' }}
                            id="standard-address"
                            label="Address 1"
                            value={this.state.address1}
                            error={this.state.address1 === ''}
                            helperText={this.state.address1 ? '' : 'Required'}
                            onChange={this.handleChange('address1')}
                            margin="normal" />
                        <TextField
                            style={{ width: '100%', marginRight: '16px' }}
                            id="standard-address"
                            label="Address 2"
                            value={this.state.address2}
                            onChange={this.handleChange('address2')}
                            margin="normal" />
                    </div>
                    <div className="split-row">
                        <TextField
                            style={{ width: '50%', marginRight: '16px' }}
                            id="standard-city"
                            label="City"
                            value={this.state.city}
                            error={this.state.city === ''}
                            helperText={this.state.city ? '' : 'Required'}
                            onChange={this.handleChange('city')}
                            margin="normal" />
                        <TextField
                            style={{ width: '50%' }}
                            id="standard-state"
                            label="State"
                            value={this.state.state}
                            error={this.state.state === ''}
                            helperText={this.state.state ? '' : 'Required'}
                            onChange={this.handleChange('state')}
                            margin="normal" />
                    </div>
                    <div className="single-row">
                        <TextField
                            style={{ width: '100%', marginRight: '16px' }}
                            id="standard-zipcode"
                            label="Zipcode"
                            value={this.state.zipcode}
                            error={this.state.zipcode === ''}
                            onChange={this.handleChange('zipcode')}
                            helperText={this.state.zipcode ? '' : 'Required'} />

                            <CardElement
                              hidePostalCode
                              onReady={this.cardOnReady}
                              onChange={this.cardOnChange}
                              className="card-info" />
                    </div>
                    <div className="single-row">
                        <Button
                          disabled={!this.canSubmit()}
                          className="trial-button"
                          variant="contained"
                          color="secondary"
                          onClick={this.handleSubmit} >
                          {'SUBMIT PAYMENT'}
                        </Button>
                    </div>
                    <div className="single-row">
                        <p className="disclaimer">{`Subscriptions will automatically renew and your credit card will be charged ${(this.state.selected===0) ? '$9.99' : '$94.99'}, plus any applicable taxes, at the start of each period, unless you unsubscribe at least 24 hours before.`}</p>
                    </div>
                </div>
                <ErrorDialog error={error} onRequestClose={this.handleCloseModal} isopen={errorOpen} />
            </div>
        );
    }
}

export default withRouter(injectStripe(Payment));
