import React, { Component } from 'react';
import './App.css';
import { Elements, StripeProvider } from 'react-stripe-elements';
import RouteComponent from './components/RouteComponent';

class App extends Component {
  render() {
    return (
      <div className="App">
        <StripeProvider apiKey="pk_live_dEIOwvPmlo9rbEkfu6HCsGbM">
            <Elements>
                <RouteComponent />
            </Elements>
        </StripeProvider>
      </div>
    );
  }
}

export default App;
