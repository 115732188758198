import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';

class Success extends PureComponent {

    render() {
        return (
            <div>Successfully Subscribed to Plan</div>
        );
    }

}

export default withRouter(Success);
