import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Payment from './Payment';
import Success from './Success';

class RouteComponent extends Component {

    render() {
        return (
            <div>
                <Router>
                  <React.Fragment>
                      <Switch>
                        <Route path="/payment" component={Payment} />
                        <Route exact path="/success" component={Success} />
                      </Switch>
                  </React.Fragment>
                </Router>
            </div>
        );
    }

}

export default RouteComponent;
